<template>
  <PDialogBody>

    <PDialogContent>
      <!-- TODO: add translation babel -->
      <p class="mb-2">Registrer paller fra andre land til tollbehandling:</p>

      <div v-if="!transactions.length">
        Ingen produkter funnet på ordre...
      </div>

      <TransactionCustomsAccordion
        v-for="transaction in transactions"
        class="mt-4"
        :key="transaction.id"
        :transaction="transaction"
      />

    </PDialogContent>

    <PDialogActions>
      <PButton
        class="ml-2"
        color="secondary"
        @click="$emit('close')"
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>
    </PDialogActions>
  </PDialogBody>
</template>

<script>
import TransactionCustomsAccordion from "./partials/TransactionCustomsAccordion.vue";

export default {
  components: { TransactionCustomsAccordion },
  props: ["orderId", "transactions"],
}
</script>
