<template>

  <div>
    <a
      href
      v-if="selectableProducts.length > 0"
      class="text-green-500 ml-16 text-sm"
      @click.prevent="addProductVisible = true"
    >
      + <span class="underline">Legg til produkt</span>
    </a>

    <portal to="modals">
      <div
        class="confirm fixed inset-0 z-50 flex items-center justify-center"
        v-if="addProductVisible"
      >
        <div class="bg-white p-8 rounded-md shadow-xl modal">

          <div class="text-lg font-medium mb-8">Legg til produkt</div>

          <PLoader v-if="isLoading" />

          <template v-else>

            <div
              class="mt-1 mb-2 py-2 px-3 border rounded-md flex items-center w-full bg-gradient-to-b from-gray-50 to-gray-200  border-gray-300"
              v-for="(product, index) in selectableProducts"
              :key="index"
            >
              <img
                :src="`${assetsBaseUrl}/media.ashx?${product.image}&template=product-image-69`"
                alt="Produktbilde"
              />

              <div class="ml-4">

                <div class="font-medium">
                  {{ product.productName }}
                </div>

                <div class="text-xs"> <!--  v-if="!isExternal && (isGL && product.isPooling) === false" -->
                  {{ product.qualityName }}
                  {{ product.treatmentIsDefault ? "" : product.treatmentName }}
                </div>

              </div>

              <div class="flex-1 flex items-center justify-end">

                <div
                  class="ml-4 text-center"
                  v-if="product.isCommission"
                >
                  <div
                    class="font-medium"
                    v-html="$tk('Common.General.Commission')"
                  ></div>
                  <div
                    class="text-sm"
                    :class="{
                      'text-green-500': product.balance > 0,
                      'text-red-500': product.balance < 0
                    }"
                    v-if="product.balance !== null"
                  >
                    <template v-if="product.balance > 0">
                      <span class="font-semibold">{{ product.balance }}</span> <span
                        v-html="$tk('Common.General.Credited').toLowerCase()"
                      ></span>
                    </template>
                    <template v-else-if="product.balance < 0">
                      <span class="font-semibold">{{ product.balance * -1 }}</span> <span
                        v-html="$tk('Common.General.Owes').toLowerCase()"
                      ></span>
                    </template>
                    <template v-else>
                      <span v-html="$tk('Common.General.Balance')"></span>:
                      <span class="font-semibold">{{ product.balance }}</span>
                    </template>
                  </div>
                </div>

                <PButton
                  class="ml-4"
                  @click="onAdd(product)"
                >
                  <PTranslation k="Common.Actions.Add" />
                </PButton>

                <!-- <div class="flex items-baseline text-lg" v-if="showPrices && product.price > 0">
                  <span class="text-xs">{{ product.currencyId }}</span> 
                  <span class="ml-1 font-semibold">{{ numberFormat(product.price, 2) }}</span>                  
                </div> -->

              </div>
            </div>

          </template>

          <div class="mt-8 flex justify-end space-x-2">

            <PButton
              color="secondary"
              @click="addProductVisible = false"
            >
              <PTranslation k="Common.Actions.Cancel" />
            </PButton>

          </div>

        </div>
      </div>
    </portal>

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import {
  filter,
  every
} from "lodash"

export default {

  props: {
    order: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      products: [],
      locationTo: null,
      isLoading: false,
      addProductVisible: false
    }
  },

  computed: {

    ...mapGetters(["location"]),

    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    },

    isGL() {
      return this.order.transactionTypeId === "GL"
    },

    selectableProducts() {
      return filter(this.products, p =>
        every(this.order.transactions, t =>
          p.productId !== t.productId &&
          p.qualityId !== t.qualityId &&
          p.treatmentId !== t.treatmentId
        )
      )
    }
  },

  methods: {

    onAdd(product) {
      this.addProductVisible = false
      this.$emit("add", product)
    },

    async init() {

      this.isLoading = true

      // try {                

      let locationTo

      if (this.order.locationIdTo) {
        try {
          locationTo = await http.get("Location", {
            params: {
              id: this.order.locationIdTo
            }
          })
        } catch (error) {
          console.error("Error init location", error)
        }
      }

      let params = {
        portalType: "PORTAL",
        transactionType: this.order.transactionType,
        truckTypeId: this.order.truckTypeId,
        orderId: this.order.id
      }

      let products = []

      if (this.isGL) {

        let poolingProducts = []
        let commissionProducts = []

        if (locationTo && locationTo.isPooling) {
          poolingProducts = await http.get("AvailableProducts", {
            params: {
              ...params,
              locationId: this.location.id,
              isPooling: true
            }
          })
        }

        if (locationTo && locationTo.isCommission) {
          commissionProducts = await http.get("AvailableProducts", {
            params: {
              ...params,
              locationId: locationTo.id,
              isCommission: true
            }
          })
        }

        products = poolingProducts.concat(commissionProducts)

      } else {

        products = await http.get("AvailableProducts", {
          params: {
            ...params,
            locationId: this.location.id
          }
        })

      }

      this.products = products

      // } catch {

      //   this.requestFailed = true

      // }

      this.isLoading = false

    },


  },



  watch: {
    order: {
      handler: function(val) {
        if (val) {
          this.init()
        }
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style scoped>
.modal {
  width: 700px;
}
</style>