<template>

  <PDialogBody>

    <PDialogContent>      
        
      <PLoader v-if="isLoading" />

      <div class="flex flex-col space-y-4" v-else>
        
        <div v-for="(t, tindex) in order.transactions" :key="tindex">
          
          <div class="bg-white flex flex-col space-y-4 p-4">
            <div class="flex space-x-4 justify-between items-center">
              <div class="flex items-center">
                <div class="h-12 w-12 flex items-center justify-center">
                  <div class="h-12 w-12 bg-contain bg-center bg-no-repeat" :style="`background-image:url('${assetsBaseUrl}/media.ashx?${t.productImage}&template=product-image-xsmall')`"></div>
                </div>
                <!-- <img :src="`${assetsBaseUrl}/media.ashx?${t.productImage}&template=product-image-xsmall`" /> -->
                <div class="ml-4">
                  <div class="font-semibold">{{ t.productName }}</div>
                  <div class="text-sm">{{ t.qualityName }}, {{ t.treatmentName }}</div>
                </div>
              </div>
              <div class="font-semibold pr-2">
                {{ t.quantity }}
              </div>
            </div>
            <div class="flex flex-col space-y-1 pl-16" v-if="t.sorts && t.sorts.length > 0">
              
              <div 
                class="text-sm font-semibold" 
                v-html="$tk('EditDialogSorts.SortedTo')"
              >:</div>

              <div v-for="(s, sindex) in t.sorts" :key="sindex">
                <div class="flex justify-between  items-center px-2 bg-gray-100">
                  <div class="flex items-center">
                    <div class="h-9 w-9 flex items-center justify-center">
                    <div class="h-8 w-8 bg-contain bg-center bg-no-repeat" :style="`background-image:url('${assetsBaseUrl}/media.ashx?${s.productImage}&template=product-image-xsmall')`"></div>
                    </div>
                    <div class="text-sm ml-2">{{ s.qualityName }}, {{ s.treatmentName }}</div>
                  </div>
                  <div class="text-sm">
                    {{ s.quantity }}
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>

      </div>
      
    </PDialogContent>

    <PDialogActions>

      <PButton 
        @click="$emit('close')" 
        color="secondary" 
        class="ml-2"        
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>
      
    </PDialogActions>  

  </PDialogBody>
</template>

<script>

import http from "@/http"

export default {
  
  props: ["orderId"],
  
  data () {
    return {
      order: null,
      isLoading: false
    }
  },

  computed: {
    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    }
  },

  methods: {
    async getSorts () {    
      
      this.isLoading = true  
      
      try {

        this.order = await http.get("Order", {
          params: {
            id: this.orderId,
            withSort: true,
            withTempSortLines: false,
            withTempSortLinesPivoted: false,
            withQualityChanges: true,
            withEmployees: false
          }
        })

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })

      }

      this.isLoading = false
    }
  },

  async created () {
    await this.getSorts()
  }
  
}
</script>

<style scoped>

td {
  padding: theme("spacing.4")
}

</style>