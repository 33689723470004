<template>
  <tr>
    <template v-if="isOpen">
      <td></td>
      <td class="pb-4">
        <div class="flex items-center">

          <button
            @click.prevent="onDelete"
            class="text-gray-700 mr-2"
            v-if="readonly === false"
          >
            <FontAwesomeIcon :icon="['far', 'trash-alt']" />
          </button>

          <PSelect
            class="w-full"
            :items="qualities"
            :disabled="readonly"
            itemText="name"
            itemValue="id"
            v-model="qualityIdReal"
            v-slot="{ item }"
          >
            <div class="flex items-center">
              <PImage
                class="w-10"
                :src="item.image"
              />
              <div class="ml-2">{{ item.name }}</div>
            </div>
          </PSelect>

        </div>
      </td>
      <td></td>
      <td class="px-2 pb-4">
        <PNumber
          v-model="quantityReal"
          :readonly="readonly"
          :min="0"
          :max="transaction.quantityOrdered"
        />
      </td>
    </template>
    <template v-else>
      <td></td>
      <td colspan="3">
        <a
          href
          class="text-green-500 underline text-sm"
          @click.prevent="open"
        >
          <FontAwesomeIcon
            :icon="['far', 'plus']"
            size="sm"
          />
          <span class="inline-block ml-1 underline">Avvikende kvalitet</span>
        </a>
      </td>

    </template>
  </tr>
</template>

<script>
import http from "@/http"
import { filter } from "lodash"
export default {

  props: {
    transaction: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    qualityIdPropertyName: {
      type: String,
      default: "qualityIdReal"
    },
    namePropertyName: {
      type: String,
      default: "qualityNameReal"
    },
    quantityPropertyName: {
      type: String,
      default: "quantityReal"
    }

  },

  data() {
    return {
      isOpen: false,
      qualityIdReal: "",
      qualityNameReal: "",
      quantityReal: 0,
      qualities: []
    }
  },

  computed: {
    returnValue() {
      return {
        transactionId: this.transaction.id,
        qualityIdReal: this.qualityIdReal,
        quantityReal: this.quantityReal,
        qualityIdPropertyName: this.qualityIdPropertyName,
        quantityPropertyName: this.quantityPropertyName
      }
    }
  },

  methods: {

    async open() {

      try {
        const qualities = await http.get("Qualities", {
          params: {
            productId: this.transaction.productId
          }
        })
        this.qualities = filter(qualities, quality => {
          return quality.show_TO === true &&
            quality.id !== this.transaction.qualityId
        })

        // if (!this.transaction["qualityIdPropertyName"]) {
        //   this.quantityReal = this.transaction.quantityPicked    
        // }

        this.isOpen = true

      } catch {
        this.qualities = []
      }
    },

    onDelete() {
      this.isOpen = false
      this.qualityIdReal = ""
      this.qualityNameReal = ""
      this.quantityReal = 0

      this.$emit("change", this.returnValue)
    }
  },

  async created() {

    if (this.transaction[this.qualityIdPropertyName]) {

      this.qualityIdReal = this.transaction[this.qualityIdPropertyName]
      this.qualityNameReal = this.transaction[this.namePropertyName]
      this.quantityReal = this.transaction[this.quantityPropertyName]

      await this.open()
    }
  },

  watch: {
    returnValue: function(val) {
      if (val.qualityIdReal) {
        this.$emit("change", val)
      }
    }
  }

}
</script>