<template>
  <div class="flex flex-col space-y-4">

    <div class="grid gap-4 grid-cols-2">

      <PText class="w-1/2" :label="this.$t('Common.Order.ReceivalDate')">
        {{ order.dateSend | date }}
      </PText>

      <PText class="w-1/2" :label="this.$t('Common.Order.MyReference')" v-if="config.theme !== 'swa'">
        {{ order.receiversRef || "-" }}
      </PText> 

      <PText class="w-1/2" :label="this.$t('Common.Order.DeliveryAddress')">
        {{ order.addressTo ? order.addressTo.name : "" || "-" }}
      </PText>

      <PText class="w-1/2" :label="this.$t('Common.Order.TransportRef')">
        {{ order.shipmentRef || "-" }}
      </PText>

      <PText class="w-1/2" :label="this.$t('Common.Order.CarNo')">
        {{ order.shipmentCarNumber || "-" }}
      </PText>

      <PText class="w-1/2" :label="this.$t('Common.Order.LoadText')">
        {{ order.loadTextTo }} {{ (order.loadTextTo ? ', ' : '') + loadType }}
      </PText>

      <PText class="w-1/2" :label="this.$t('Common.Order.CarType')">
        {{ order.truckTypeId || "-" }}
      </PText>
      
      <PText class="w-1/2" :label="this.$t('Common.Order.TransportText')">
        {{ order.transportText || "-" }}
      </PText>

      <PText class="w-1/2" :label="this.$t('OrderDialog.SelfPickup')">
        {{ order.noTransport ? $t("Common.General.Yes") : $t("Common.General.No") }}
      </PText>

      <template v-if="config.theme === 'swa'">

        <PText class="w-1/2" :label="this.$t('Common.Order.Sealing')">
          {{ order.sealingId || "-" }}
        </PText>
      
        <PText class="w-1/2" :label="this.$t('Common.Order.Cartman')">
          {{ order.cartmanName || "-" }}
        </PText>

        <PText class="w-1/2" :label="this.$t('Common.Order.CarNo2')">
          {{ order.shipmentCarNumber2 || "-" }}
        </PText>      
      
      </template>
      
    </div>
    
  </div>
</template>

<script>

import { getConfig } from "@/config"

const config = getConfig()

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      config
    }
  },

  computed: {
    loadType () {
      return this.$store.getters.location.loadType
    }
  }
}
</script>