<template>
  <div class="flex flex-col space-y-4">

    <div class="flex space-x-4">
            
      <PDatePicker              
        v-if="canEdit"
        class="w-1/2"                        
        v-model="dateSend"
        :required="true"
        :label="$t('Common.Order.Sent')"
      />
      <PText
        v-else
        class="w-1/2"
        :label="$t('Common.Order.Sent')"
      >
        {{ dateSend | date }}
      </PText>

      <PLocationPicker
        v-if="canEdit"
        class="w-1/2"
        :label="$tk('Common.Order.To')" 
        :value="locationIdTo"
        :pooling="true" 
        :corporate="true"
        :required="true"
        @select="onLocationToSelect" 
      /> <!-- :disabled="isUpdating || isModified" -->
      <PText
        v-else-if="view === 'reception'"
        class="w-1/2"        
        :label="$t('Common.Order.From')"
      >
        {{ locationNameFrom }}
      </PText>
      <PText
        v-else
        class="w-1/2"        
        :label="$t('Common.Order.To')"
      >
        {{ locationNameTo }}
      </PText>

    </div>

    <div class="flex space-x-4">
      
      <PInput
        v-if="canEdit"
        class="w-1/2"
        :label="$tk('Common.Order.SendersRef')"     
        v-model="sendersRef"                      
      />
      <PText
        v-else
        class="w-1/2"        
        :label="$t('Common.Order.SendersRef')"
      >
        {{ sendersRef || "-" }}
      </PText>

      <PInput
        v-if="canEdit"
        class="w-1/2"
        :label="$tk('Common.Order.ReceiversRef')"
        :required="true"        
        v-model="receiversRef"                      
      />
      <PText
        v-else
        class="w-1/2"        
        :label="$t('Common.Order.ReceiversRef')"
      >
        {{ receiversRef || "-" }}
      </PText>

    </div>

  </div>
</template>

<script>

import { mapGetters } from "vuex"
import { every } from "lodash"

export default {
  
  props: {
    value: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      dateSend: "",
      sendersRef: "",
      receiversRef: "",
      locationIdFrom: "",
      locationNameFrom: "",
      locationIdTo: "",
      locationNameTo: ""      
    }
  },

  computed: {

    ...mapGetters(["location"]),

    canEdit () {
      return this.locationIdFrom === this.location.id && this.transactionStatusId !== 5
    },

    fieldValues () {
      return {
        dateSend: this.dateSend,
        sendersRef: this.sendersRef,
        receiversRef: this.receiversRef,
        locationIdTo: this.locationIdTo
      }
    },

    transactions () {
      return this.value.transactions
    },

    transactionStatusId () {
      return this.value.transactionStatusId
    }

  },

  methods: {

    onLocationToSelect (location) {

      const validLocation = every(this.transactions, t =>       
        (t.isPooling && t.isCommission) ||
        (
          t.isPooling === location.isPooling ||
          t.isCommission !== location.isCommission
        )
      )

      if (!validLocation) {
        this.$notifyError(
          this.$tk("GlFields.InvalidLocationTitle", true),
          this.$tk("GlFields.InvalidLocationText", true)
        )
      } else {
        this.locationIdTo = location.id
        this.locationNameTo = location.name
      }

    }

  },

  created () {
    this.dateSend = this.value.dateSend
    this.sendersRef = this.value.sendersRef
    this.receiversRef = this.value.receiversRef
    this.locationIdFrom = this.value.locationIdFrom
    this.locationNameFrom = this.value.locationNameFrom
    this.locationIdTo = this.value.locationIdTo
    this.locationNameTo = this.value.locationNameTo
  },

  watch: {
    fieldValues: function (val) {
      this.$emit("input", val)
    }
  }

}
</script>