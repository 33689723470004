<template>
  <div class="flex-1 flex flex-col overflow-hidden">
    
    <PDialogContent class="bg-gray-100">
      
      <PLoader v-if="isLoading" />
      
      <iframe ref="iframe" class="flex h-full w-full" v-if="waybillSrc" :src="waybillSrc"></iframe>

    </PDialogContent>

    <PDialogActions>
      <PButton 
        @click="$emit('close')" 
        color="secondary" 
        class="ml-2"        
      >
        <span v-html="$tk('Common.Actions.Close')"></span>
      </PButton>
    </PDialogActions> 

  </div>
</template>

<script>

import http from "@/http"
import axios from "axios"
import TokenService from "@/services/TokenService"
import { getConfig } from "@/config"

const config = getConfig();

export default {
  
  name: "p-order-waybill",
  
  props: ["orderId", "printWaybill"],
  
  data () {
    return {    
      isLoading: false,
      waybillSrc: ""
    }
  },

  methods: {

    async getOrder () {

      this.isLoading = true

      try {

        this.order = await http.get("order", { 
          params: { 
            id: this.orderId 
          }
        })

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })

      }

      this.isLoading = false
    },

    async getWaybill () {
      const token = TokenService.getToken()      
      this.isLoading = true
      try {
                
        const url = `${config.apiUrl}/WaybillDocument?orderid=${this.orderId}&encoding=b64&authtoken=${encodeURIComponent(token)}`
        const data = await axios.get(url).then(response => response.data)    
        this.waybillSrc = `data:application/pdf;base64,${data}`
        
      } catch (error) {
        this.$store.dispatch("notify", { type: "negative", text: error.reason })
      }
      this.isLoading = false
    },

    async init () {

      await this.getOrder()
      
      // const allPicked = every(this.order.transactions, t => {
      //   return t.quantityPicked > 0
      // })

      // if (allPicked) {

        await this.getWaybill()

        if (this.printWaybill) {
          let iframe = this.$refs.iframe
          setTimeout(() => { 
            iframe.focus()
            iframe.contentWindow.print()
            // this.$emit("waybillPrinted")
          }, 1000)
        }

      // } else {

      //   this.$store.dispatch("notify", { 
      //     type: "negative", 
      //     text: this.$tk("EditDialogWaybill.PickedQuantityMissing", true)
      //   })

      // }    
    }
  },
  
  async created () {
    await this.init()
  }
}
</script>